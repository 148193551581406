
export const auctionStatus = {
    LIVE_RESERVE_MET: 'liveReserveMet',
    LIVE_RESERVE_NOT_MET: 'liveReserveNotMet',
    LIVE: 'live',
    COMPLETED_SOLD: 'completedSold',
    COMPLETED_RESERVED_NOT_MET: 'completedReserveNotMet',
    PENDING_APPROVAL: 'pendingApproval',
    APPROVED: 'approved',
    REJECTED: 'rejected',
};
